@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}

.container {
	display: flex;
	justify-content: center;
	width: 100%;
	margin: auto;
}

.spinner {
	animation: rotate 2s linear infinite;
	width: 50px;
	height: 50px;
	padding: 5px 8px;
}

.path {
	stroke: #7256ce;
	stroke-linecap: round;
	animation: dash 1.5s ease-in-out infinite;
}
